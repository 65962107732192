const v8Attribution = (function () {
  const cookies = document.cookie;
  const cookieName = 'v8_utm_cookie';
  let utmCookie = '';
  let cookieSet = false;

  function init() {
    getCookie(cookieName);
    utmCookie = getUtmParams();

    setV8Cookie();
  }

  function getUtmParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const utmTargetParams = {
      Source: urlParams.get('utm_source'),
      Medium: urlParams.get('utm_medium'),
      Campaign: urlParams.get('utm_campaign'),
      Referrer: document.referrer ? document.referrer : null,
    };

    return utmTargetParams;
  }

  function getCookie(cookieName) {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(cookies);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        cookieSet = true;
        return;
      }
    }
    return '';
  }

  function setV8Cookie() {
    const isNull = Object.values(utmCookie).every((value) => {
      if (value === null) {
        return true;
      }

      return false;
    });

    if (isNull) {
      return;
    }

    if (!cookieSet) {
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie =
        cookieName +
        '=' +
        JSON.stringify(utmCookie) +
        ';' +
        expires +
        ';path=/';
    }
  }

  init();
})();
